





































import { defineComponent, ref } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'Signin',
  setup(props, context) {
    const auth = useAuth();
    const notification = useNotification();

    const inputPasswordType = ref('password');
    const togglePasswordType = () => {
      inputPasswordType.value = inputPasswordType.value === 'password' ? 'text' : 'password';
    };

    const step = ref(1);
    const email = ref('');
    const password = ref('');
    const code = ref('');
    const isSending = ref(false);
    const isKeepSession = ref(false);

    const signin = async () => {
      isSending.value = true;
      try {
        await auth.signin(email.value, password.value);
        step.value = 2;
      } catch (error) {
        notification.error(error);
      }
      isSending.value = false;
    };

    const verify = async () => {
      isSending.value = true;
      try {
        await auth.verify(email.value, password.value, code.value, isKeepSession.value);
        context.root.$router.push('/');
      } catch (error) {
        notification.error(error);
      }
      isSending.value = false;
    };

    return {
      step,
      email,
      password,
      code,
      isSending,
      inputPasswordType,
      togglePasswordType,
      signin,
      verify,
      isKeepSession,
    };
  },
});
